import { notDesktop, styled } from "@obvio/app";
import { Grid } from "@obvio/ui";

import { FloatImage } from "./FloatImage";

import type { ImageAsset } from "@obvio/app";
import type { ReactElement } from "react";

const Wrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

const ImgWrap = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1.25;
`;

type ImageFloatProps = {
  image: ImageAsset;
  image2: ImageAsset;
};

export function ImageImage({ image, image2 }: ImageFloatProps): ReactElement {
  return (
    <Wrap templateColumns="1fr 1fr" gap="medium">
      <ImgWrap>
        <FloatImage img={image} />
      </ImgWrap>

      <ImgWrap>
        <FloatImage img={image2} />
      </ImgWrap>
    </Wrap>
  );
}
