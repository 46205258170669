import { Image, styled } from "@obvio/app";
import { randomInt } from "@obvio/utils";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import type { ImageProps } from "@obvio/app";
import type { ReactElement } from "react";

const Wrap = motion(styled.div`
  width: 100%;
  height: 100%;
`);

export function FloatImage(img: ImageProps): ReactElement {
  const ref = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const random = randomInt(-2.5, 2.5);

  const transformed = useTransform(
    scrollYProgress,
    [0, 1],
    ["0rem", `${random}rem`],
  );

  return (
    <Wrap ref={ref} style={{ y: transformed }}>
      <Image {...img} />
    </Wrap>
  );
}
